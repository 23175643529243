import * as React from "react"
import "./testimonials.scss"
import {useTranslation} from "react-i18next";
import Section from "../Section/section";
import Container from "../Container/container";
import {SectionHeader} from "../Section/SectionHeader/section-header";
import "../../page-data/testimonials-data";
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import TestimonialCard from "./TestimonialCard/testimonial-card";
import {Linolade} from "../Linolade/linolade";


type TestimonialsDataProps = {
    imageUrl: string,
    opinion: string,
    provider: string,
    company: string,
    country: string
}

const Testimonials = () => {
    const {t} = useTranslation()

    const {allTestimonialsDataJson: {nodes}, allImageSharp: {edges}} = useStaticQuery(
        graphql`
            query {
              allImageSharp(filter: {fixed: {originalName: {regex: "/logo/"}}}) {
                edges {
                  node {
                    fixed {
                      originalName
                    }
                    gatsbyImageData(layout: FULL_WIDTH, width: 100, transformOptions: {fit: CONTAIN})
                  }
                }
              }
              allTestimonialsDataJson {
                nodes {
                  imageUrl
                  opinion
                  provider
                  country
                  company
                }
              }
            }
    `)
    return (
        <Section sectionId="testimonials">
            <Linolade/>
            <SectionHeader text={t('testimonials.text')} highlightedText={t('testimonials.highlightedText')}/>
            <Container>
                <p>{t('testimonials.description')}</p>
            </Container>
            <div className="testimonials-cards">
                {
                    nodes.map(( n: any, i:number) =>
                        <TestimonialCard
                            imageUrl={n.imageUrl}
                            opinion={n.opinion}
                            provider={n.provider}
                            company={n.company}
                            country={n.country}
                            gatsbyImageData={edges.find((e: any) => e.node.fixed.originalName === n.imageUrl).node.gatsbyImageData}
                            key={i}
                            />
                    )
                }
            </div>
        </Section>
    )
}

export default Testimonials