import * as React from "react"
import "./section.scss"
import {PropsWithChildren} from "react"

interface SectionProps extends PropsWithChildren{
    sectionId: string
}

const Section = (props: SectionProps) => {
    return (
        <section id={props.sectionId} className="section">
            {props.children}
        </section>
    )
}

export default Section