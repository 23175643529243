import {useTranslation} from "react-i18next";
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage, getImage, IGatsbyImageData} from "gatsby-plugin-image";
import * as React from "react";
import "./testimonial-card.scss"

type TestimonialsDataProps = {
    imageUrl: string,
    opinion: string,
    provider: string,
    company: string,
    country: string,
    gatsbyImageData: IGatsbyImageData,
    key: number;
}

const TestimonialCard = (data: TestimonialsDataProps) => {
    const {t} = useTranslation()

    return (
        <div className="testimonials-card">
            <div className="testimonials-card_image">
                <GatsbyImage
                    image={data.gatsbyImageData}
                    alt={data.company}/>
            </div>
            <blockquote className="testimonials-card_opinion">{data.opinion}</blockquote>
            <address className="testimonials-card_address">
                {data.provider} <br/>
                {data.company} | {data.country}
            </address>
        </div>
    )
}

export default TestimonialCard