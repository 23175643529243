import * as React from "react"
import "./what-can-we-do.scss"
import Section from "../Section/section";
import {SectionHeader} from "../Section/SectionHeader/section-header";
import {Linolade} from "../Linolade/linolade";
import {useTranslation} from "react-i18next";
import Container from "../Container/container";
import Space from "../Space/space";

const WeDo = (props: { title: string, description: string }) => (
    <div className="what-can-we-do_option">
        <div className="what-can-we-do_option_rect"></div>
        <h1>{props.title}</h1>
        <p>{props.description}</p>
    </div>
)

const WhatCanWeDo = () => {
    const {t} = useTranslation()

    return (
        <Section sectionId="what-can-we-do">
            <Linolade/>
            <SectionHeader text={t('whatCanWeDo.text')} highlightedText={t('whatCanWeDo.highlightedText')}/>
            <Container>

                <p>{t('whatCanWeDo.description')}</p>
                <Space/>
                <div className="what-can-we-do_options">
                    <WeDo title={t('whatCanWeDo.softwareDevelopment.title')}
                          description={t('whatCanWeDo.softwareDevelopment.description')}/>
                    <WeDo title={t('whatCanWeDo.outsourcingIt.title')}
                          description={t('whatCanWeDo.outsourcingIt.description')}/>
                    <WeDo title={t('whatCanWeDo.marketing.title')}
                          description={t('whatCanWeDo.marketing.description')}/>
                </div>
            </Container>
        </Section>
    )
}

export default WhatCanWeDo