import * as React from "react"
import PageTitle from "../components/PageTitle/page-title";
import Section from "../components/Section/section";
import {SectionHeader} from "../components/Section/SectionHeader/section-header";
import {useTranslation} from "react-i18next";
import {graphql, HeadFC} from "gatsby";
import Container from "../components/Container/container";
import {Linolade} from "../components/Linolade/linolade";
import {NegativeSpace} from "../components/NegativeSpace/negative-space";
import Contact from "../components/Contact/contact";
import Testimonials from "../components/Testimonials/testimonials";
import WhatCanWeDo from "../components/WhatCanWeDo/what-can-we-do";
import Navbar from "../components/Navbar/navbar";
import Footer from "../components/Footer/footer";
import {SEO} from "../components/Seo/seo";
import WelcomeSection from "../components/WelcomeSection/welcome-section";

const IndexPage = () => {
    const {t} = useTranslation();
    return (
        <main>
            <PageTitle/>
            <NegativeSpace/>
            <WelcomeSection/>
            <NegativeSpace/>
            <WhatCanWeDo/>
            <NegativeSpace/>
            <Testimonials/>
            <NegativeSpace/>
            <Contact/>
        </main>
    )
}

export default IndexPage;

export const Head: HeadFC = () => (
    <>
        <SEO/>
    </>
);

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;