import * as React from "react"
import Section from "../Section/section"
import {useTranslation} from "react-i18next"
import {SectionHeader} from "../Section/SectionHeader/section-header"
import {Linolade} from "../Linolade/linolade"
import {PropsWithChildren} from "react"
import "./contact.scss"
import ShapesAsset from "../../../assets/contact_shapes.svg"
import Container from "../Container/container";
import {Link} from "gatsby-plugin-react-i18next";

interface ContactOptionProps extends PropsWithChildren {
    optionHeader: string
}

const ContactOption = (props: ContactOptionProps) =>
    (
        <div className="contact-option">
            <div className="contact-option_header">{props.optionHeader}</div>
            <div className="contact-option_content">{props.children}</div>
        </div>
    )


const ContactGrid = () => {
    const {t} = useTranslation()

    return (
        <div className="contact_grid">
            <ContactOption optionHeader={t('contact.email')}>
                <div className="contact-option_content_link-container">
                    <Link to={"mailto:contact@markana.it"}>contact@markana.it</Link>
                </div>
            </ContactOption>
            <ContactOption optionHeader={t('contact.phone')}>
                <div className="contact-option_content_link-container">
                    <Link to={"tel:/+48-604-170-420"}>+48 604 170 420</Link>
                </div>
                <div className="contact-option_content_link-container">
                    <Link to={"tel:/+48 511 542 981"}>+48 511 542 981</Link>
                </div>
            </ContactOption>
            <ContactOption optionHeader={t('contact.postalAddress')}>
                <address>
                    <div className="contact-option_content_link-container">
                        43-400 Cieszyn
                    </div>
                    <div className="contact-option_content_link-container">
                        Frysztacka 130
                    </div>
                </address>
            </ContactOption>
            <ContactOption optionHeader={t('contact.companyDetails')}>
                <address>
                    <div className="contact-option_content_link-container">
                        NIP: 5482759239
                    </div>
                    <div className="contact-option_content_link-container">
                        REGON: 528375174
                    </div>
                </address>
            </ContactOption>
        </div>
    )
}

const Contact = () => {
    const {t} = useTranslation()

    return (
        <Section sectionId="contact">
            <Linolade/>
            <SectionHeader text={t('contact.text')} highlightedText={t('contact.highlightedText')}/>
            <Container>
                <p>{t('contact.description')}</p>
                <ContactGrid/>
            </Container>
        </Section>
    )
}

export default Contact;