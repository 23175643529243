import * as React from "react"
import "./section-header.scss"

interface SectionHeaderText {
    text: string;
    highlightedText: string;
}

export const SectionHeader = (props: SectionHeaderText) =>
    (
        <header className="section-header">
            <h1>{props.text} <span>{props.highlightedText}</span></h1>
        </header>
    )
