import * as React from "react"
import "./page-title.scss"
import Strips from "../../../assets/strips.svg"

const PageTitle = () => {
    return (
        <header className="page-title">
            <p className="page-title_pre-title"><strong>in</strong> Markana <strong>we create</strong></p>
            <h1 className="page-title_title">Professional Apps</h1>
            <p className="page-title_subtitle">Reliable. Fast. Exceptional.</p>
            <div className="page-title_strips_container">
                <Strips className="page-title_strips"/>
            </div>
        </header>
    )
}

export default PageTitle;