import Section from "../Section/section";
import {Linolade} from "../Linolade/linolade";
import {SectionHeader} from "../Section/SectionHeader/section-header";
import Container from "../Container/container";
import * as React from "react";
import {useTranslation} from "react-i18next";


const WelcomeSection = () => {
    const {t} = useTranslation();

    return (
        <Section sectionId="welcome">
            <Linolade/>
            <SectionHeader text={t('welcomeSection.sectionHeader.text')} highlightedText={t('welcomeSection.sectionHeader.highlightedText')}/>
            <Container>
                <p>{t('welcomeSection.description')}</p>
            </Container>
        </Section>
    )
};

export default WelcomeSection;